import { OnyxAddressType, OnyxPaginated } from '@onyx/angular';
import { BASES_MOCK } from '../../../management-panel/bases/common/mocks/bases.mock';
import { INTEGRATIONS_VEHICLES_MOCK } from '../../../management-panel/integrations/common/mocks/integrations-vehicles-mocks';
import { ContractType } from '../enums/contract-type';
import { DriverSettlementMethod } from '../enums/driver-settlement-method';
import { Driver } from '../interfaces/driver';

export const DRIVERS_MOCK: OnyxPaginated<Driver> = {
  items: [
    {
      uuid: '1',
      assignedBase: BASES_MOCK[0],
      driverData: {
        firstName: 'Patryk',
        lastName: 'Kawiak',
        birthDate: '2005-08-21',
        nationality: 'pl',
        privatePhone: { number: '554564123', areaCode: '+48' },
        workPhone: { number: '214693120', areaCode: '+48' },
        privateEmail: 'p.kawiak@onyxtms.com',
        peselNumber: '05282114261',
        homeAddress: {
          type: OnyxAddressType.CUSTOM,
          label: '1 1/1, 1 1, PL',
          street: 'Warszawska',
          houseNumber: '1',
          apartmentNumber: '2',
          zipCode: '550-55',
          city: 'Grodno',
          countryCode: 'pl',
          coordinates: { latitude: 1, longitude: 1 },
        },
      },
      employmentConditions: {
        contractType: ContractType.EMPLOYMENT_CONTRACT,
        settlementMethod: DriverSettlementMethod.DAILY_RATE,
        dailyRate: { value: 15, currency: 'pln', date: '2025-09-07' },
        contractTerm: {
          from: '2024-09-06',
          to: '2024-10-31',
          indefinite: false,
        },
        scans: ['a93ad3c0-7ce7-468a-a316-f3a9e9ae8916'],
      },
      skills: {
        languages: ['polish', 'english'],
        technicalSkills: ['pallet-truck', 'hds-crane', 'hds-crane'],
        semiTrailers: [
          'van-reefer',
          'hook-lift-reefer',
          'hook-lift-reefer',
          'van-reefer',
        ],
        experienceLevel: '3',
      },
      countries: { group: 'national', whitelist: ['pl'] },
      note: 'Zepsuty telefon służbowy',
      driversLicenseAndProfessionalQualifications: {
        categories: [
          { category: 'b', expirationDate: { date: '2025-09-07' } },
          { category: 'c', expirationDate: { date: '2027-09-07' } },
        ],
        driversLicenseNumber: '123',
        scans: [],
      },
      driverCard: {
        number: '123421234512345',
        issuingCountry: 'pl',
        expirationDate: '2023-09-30',
        scans: [
          'c2711e61-990e-402e-9d83-fe21b7ee1473',
          'c2711e61-990e-402e-9d83-fe21b7ee1473',
        ],
      },
      identityDocuments: {
        passport: {
          number: '123232323',
          issuingCountry: 'pl',
          expirationDate: '2024-10-20',
          scans: ['f42035f7-671c-436f-b20a-e2a8151b785d'],
        },
        identityDocument: {
          number: '123232323',
          issuingCountry: 'pl',
          expirationDate: '2022-02-12',
          scans: ['f42035f7-671c-436f-b20a-e2a8151b785d'],
        },
        residenceCard: {
          number: '',
          issuingCountry: 'pl',
          expirationDate: '2024-10-20',
          scans: ['f42035f7-671c-436f-b20a-e2a8151b785d'],
        },
        polishCard: {
          number: '',
          issuingCountry: 'pl',
          expirationDate: '2024-10-20',
          scans: ['f42035f7-671c-436f-b20a-e2a8151b785d'],
        },
      },
      clearCriminalRecordCertificate: {
        issuingDate: '2024-10-10',
        scans: ['f42035f7-671c-436f-b20a-e2a8151b785d'],
      },
      visas: [
        {
          country: 'by',
          expirationDate: '2024-10-26',
          scans: ['324e1e14-6555-477a-bd80-59d5b84b8e12'],
        },
        {
          country: 'de',
          expirationDate: '2024-09-26',
          scans: ['324e1e14-6555-477a-bd80-59d5b84b8e12'],
        },
      ],
      permissions: [
        {
          permissionType: 'forklift',
          expirationDate: '2024-09-26',
          scans: ['2453dd05-c8e5-4716-a513-8edeb1a24b28'],
        },
      ],
      assignedVehicle: INTEGRATIONS_VEHICLES_MOCK.items[0].vehicle,
    },
    {
      uuid: '2-c8e5',
      assignedBase: BASES_MOCK[1],
      driverData: {
        firstName: 'Michał',
        lastName: 'Skrzel',
        birthDate: '1998-08-14',
        nationality: 'de',
        privatePhone: { number: '442642655', areaCode: '+48' },
        workPhone: { number: '254454652', areaCode: '+48' },
        privateEmail: 'm.skrzel@onyxtms.com',
        peselNumber: '98081456744',
        homeAddress: {
          type: OnyxAddressType.CUSTOM,
          label: '4 3/55, 55 555, AL',
          street: 'Kleinplatz',
          houseNumber: '15',
          apartmentNumber: '3',
          zipCode: '80-60',
          city: 'Dortmund',
          countryCode: 'de',
          coordinates: { latitude: 1, longitude: 1 },
        },
      },
      employmentConditions: {
        contractType: ContractType.B2B,
        settlementMethod: DriverSettlementMethod.MILEAGE_RATE,
        netMileagePatePerKm: { value: 15, currency: 'eur', date: null },
        contractTerm: {
          from: '2023-03-11',
          to: '2025-10-1',
          indefinite: false,
        },
        scans: ['a93ad3c0-7ce7-468a-a316-f3a9e9ae8916'],
        nipNumber: '005520532',
        companyName: 'ONYX ALFA',
      },
      skills: {
        languages: ['german', 'english'],
        technicalSkills: ['pallet-truck', 'hds-crane'],
        semiTrailers: ['flatbed-reefer', 'hook-lift-reefer'],
        experienceLevel: '4',
      },
      countries: { group: 'national', whitelist: ['de', 'gb'] },
      driversLicenseAndProfessionalQualifications: {
        categories: [{ category: 'b', expirationDate: { date: '2025-01-07' } }],
        professionalQualificationsExpirationDate: '2026-02-11',
        driversLicenseNumber: '555',
        scans: ['c2711e61-990e-402e-9d83-fe21b7ee1473'],
      },
      driverCard: {
        number: '723451232519345',
        issuingCountry: 'de',
        expirationDate: '2025-09-30',
        scans: ['c2711e61-990e-402e-9d83-fe21b7ee1473'],
      },
      identityDocuments: {
        identityDocument: {
          number: '123232323',
          issuingCountry: 'pl',
          expirationDate: '2025-02-12',
          scans: ['f42035f7-671c-436f-b20a-e2a8151b785d'],
        },
      },
      clearCriminalRecordCertificate: {
        issuingDate: '2025-09-27',
        scans: ['f895efe8-cce7-4be9-a130-35fd0872f61e'],
      },
      visas: [
        {
          country: 'by',
          expirationDate: '2024-09-26',
          scans: ['324e1e14-6555-477a-bd80-59d5b84b8e12'],
        },
      ],
      permissions: [],
      assignedVehicle: INTEGRATIONS_VEHICLES_MOCK.items[0].vehicle,
    },
    {
      uuid: '122',
      driverData: {
        firstName: 'Adam',
        lastName: 'Wątel',
        birthDate: '1977-04-15',
        nationality: 'es',
        privatePhone: { number: '665443765', areaCode: '+48' },
        workPhone: { number: '872458905', areaCode: '+48' },
        privateEmail: 'adam.wat22@wp.pl',
        peselNumber: '77041546169',
        homeAddress: {
          type: OnyxAddressType.CUSTOM,
          label: '1 1/1, 1 1, BE',
          street: 'Drigon',
          houseNumber: '5',
          apartmentNumber: '',
          zipCode: '5055-44',
          city: 'Barcelona',
          countryCode: 'es',
          coordinates: { latitude: 1, longitude: 1 },
        },
      },
      employmentConditions: {
        contractType: ContractType.MANDATE_CONTRACT,
        settlementMethod: DriverSettlementMethod.BASE_PLUS_PERCENTAGE,
        baseRate: { value: 1200, currency: 'pln', date: null },
        freightPercentage: 40,
        contractTerm: {
          from: '2024-09-19',
          to: '2024-09-30',
          indefinite: false,
        },
        scans: ['d54239a4-3e5a-42cb-a1b2-ee7ef40b7b54'],
      },
      skills: {
        languages: ['french'],
        technicalSkills: ['forklift', 'pallet-truck'],
        semiTrailers: ['flatbed-reefer', 'hook-lift-reefer'],
        experienceLevel: '5',
      },
      countries: { group: 'national', whitelist: ['pl'] },
      note: 'Problemy z tachografem',
      driversLicenseAndProfessionalQualifications: {
        categories: [
          { category: 'b', expirationDate: { date: null, indefinite: true } },
        ],
        driversLicenseNumber: '123123312123123',
        scans: [],
      },
      driverCard: {
        number: '123123123123123',
        issuingCountry: 'pl',
        expirationDate: '2024-12-26',
        scans: ['ec0c30bc-6301-47ae-b8d5-8d5b115e64d6'],
      },
      identityDocuments: {
        identityDocument: {
          number: '123123123',
          issuingCountry: 'pl',
          expirationDate: '2024-09-29',
          scans: ['209608d9-c4a5-47ae-914e-448889712119'],
        },
      },
      clearCriminalRecordCertificate: {
        issuingDate: '2024-09-26',
        scans: ['840a66c0-a34d-4a89-8d18-329f6f87d1ab'],
      },
      visas: [],
      permissions: [],
      assignedBase: null,
      assignedVehicle: INTEGRATIONS_VEHICLES_MOCK.items[0].vehicle,
    },
  ],
  limit: 1,
  page: 10,
  totalItems: 1,
};
