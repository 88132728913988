import { OnyxPaginated } from '@onyx/angular';
import { IntegrationOrigin } from '../enums/integration-origin';
import { IntegrationStatus } from '../enums/integration-status';
import { TelematicsProvider } from '../enums/telematics-provider';
import { IntegrationVehicle } from '../interfaces/integration-vehicle';

export const INTEGRATIONS_VEHICLES_MOCK: OnyxPaginated<IntegrationVehicle> = {
  items: [
    {
      uuid: '71e588fe-7b9b-4f1c-b3f4-956ab84da883',
      businessUuid: '123123-030442-3-4',
      telematicsProvider: TelematicsProvider.ONYX,
      deviceId: '946573234',
      registrationNumber: 'CT2757Y',
      origin: IntegrationOrigin.MANUAL,
      vehicle: {
        uuid: '4cc54ed2-a7fd-491f-8920-115726c4edf8',
        generalInformation: {
          category: 'straight-truck',
          type: 'chemical-tank',
          registrationNumber: 'OWABLD0',
          vinNumber: '3HP6AKHY6ATLEH2A6',
          manufactureYear: 2010,
          firstRegistrationDate: '2014-09-19',
          make: 'Renault',
          model: 'Trafic',
          sideNumber: null,
          maxSpeed: 123,
          initialMileage: null,
          state: 'active',
          planningMode: 'auto',
        },
        fuelTankCapacity: {
          fuelType: 'diesel',
          mainTank: 1200,
          adBlueTank: null,
          additionalTank: null,
          generatorFuelTank: null,
          totalCapacity: 1200,
        },
        telematics: {
          deviceId: '946573234',
          systemProvider: 'formula',
          mileage: null,
        },
        vehicleMeasurements: {
          length: 330,
          width: 330,
          height: 240,
          axleCount: 2,
          curbWeight: 3400,
        },
        cargoSpace: {
          loadCapacity: 280,
          volumeCapacity: 1000,
          chambersNumber: 2,
          tankCertificates: ['hacap'],
          dischargeTypes: ['pump'],
          europalletsNumber: null,
          length: null,
          width: null,
          height: null,
        },
        registrationCertificate: {
          technicalInspectionExpiryDate: '2025-12-19',
          registrationCertificateScanFileUuid: [
            'd84ecdd9-42b6-4cb5-8d4d-a3f8d7574b48',
          ],
        },
        co2Emissions: {
          emissionStandard: 'euro-1',
          emissionCertificate: '4a50ff47-9482-4ccf-b39a-8e890eab96ac',
        },
        thirdPartyLiabilityInsurance: {
          type: 'third-party-liability',
          contractFirstPageScan: '4544d9cb-1a80-4d3b-a853-a979de097627',
          insuranceNumber: '07/12',
          expiryDate: '2025-07-19',
          annualCost: {
            date: '2024-09-19',
            value: 1200,
            currency: 'eur',
          },
          insuranceSum: {
            date: '2024-09-19',
            value: 120000,
            currency: 'eur',
          },
        },
        comprehensiveInsurance: null,
        licensesAndPermits: {
          communityLicenseAbstractNumber: null,
          permitAbstractNumber: null,
        },
        additionalParameters: {
          adrClasses: null,
          isLowDeck: null,
          beltsNumber: null,
          hooksNumber: null,
          hasSanitaryEpidemiologicalStation: true,
          hasHaccp: true,
          hasHdsCrane: null,
          matsNumber: null,
          coilWellLength: null,
          hasCustomsSecurity: true,
          trailerSize: null,
          semiTrailerSize: null,
          hasLoadingsRamps: null,
          temperatureRange: null,
          hasTailLift: null,
          hasDumpContainer: null,
          hasForklift: null,
          otherParameters: null,
        },
        location: {
          latitude: null,
          longitude: null,
        },
        ownership: {
          type: 'own',
          vehicleValue: {
            date: '2024-09-19',
            value: 1200000,
            currency: 'eur',
          },
          depreciationPeriod: {
            from: '2024-04-19',
            to: '2025-04-19',
          },
          buyout: null,
          installment: null,
          selfDeposit: null,
          firstAndLastInstallmentDate: null,
          margin: null,
          interestRateType: null,
          isForSale: false,
        },
        allowedCountries: {
          group: 'europe',
          whitelist: [
            'ad',
            'al',
            'at',
            'az',
            'ba',
            'be',
            'bg',
            'by',
            'ch',
            'cy',
            'cz',
            'de',
            'dk',
            'ee',
            'es',
            'fi',
            'fr',
            'gb',
            'ge',
            'gr',
            'hr',
            'hu',
            'ie',
            'is',
            'it',
            'li',
            'lt',
            'lu',
            'lv',
            'md',
            'me',
            'mk',
            'mt',
            'nl',
            'no',
            'pl',
            'pt',
            'ro',
            'rs',
            'ru',
            'se',
            'si',
            'sk',
            'tr',
            'ua',
            'us',
          ],
        },
        tachograph: {
          model: 'model-4-0',
          make: 'vdo',
          nextLegalizationDate: '2025-07-19',
        },
        status: {
          value: 'active',
          color: null,
        },
        trailerUuid: null,
        drivers: {
          primaryDriver: null,
          secondaryDriver: null,
        },
        businessUuid: '25caa842-9765-480b-9436-0fc34600fbf7',
      },
      status: IntegrationStatus.ONLINE,
      lastActivityAt: '2024-09-25T14:15:41+02:00',
    },
    {
      uuid: '81e585fe-7b9b-4f1c-b3f4-956ab84da883',
      businessUuid: '1-123123-030442-3-4',
      telematicsProvider: TelematicsProvider.ONYX,
      deviceId: '4253695234',
      registrationNumber: 'GKW1155',
      origin: IntegrationOrigin.LISTED,
      vehicle: null,
      status: IntegrationStatus.OFFLINE,
      lastActivityAt: '2024-09-19T07:15:41+02:00',
    },
    {
      uuid: '01e588fe-7b9b-4f1c-b3f4-956a884da883',
      businessUuid: '661234-51024',
      telematicsProvider: TelematicsProvider.ONYX,
      deviceId: '84701274896',
      registrationNumber: 'CT2757Y',
      origin: IntegrationOrigin.LOGGED,
      vehicle: {
        uuid: '4cc54ed2-a7fd-491f-8920-115726c4edf8',
        generalInformation: {
          category: 'straight-truck',
          type: 'chemical-tank',
          registrationNumber: 'GDWBLD0',
          vinNumber: '3HP6AKHY6ATLEH2A6',
          manufactureYear: 2010,
          firstRegistrationDate: '2014-09-19',
          make: 'Renault',
          model: 'Trafic',
          sideNumber: null,
          maxSpeed: 123,
          initialMileage: null,
          state: 'active',
          planningMode: 'auto',
        },
        fuelTankCapacity: {
          fuelType: 'diesel',
          mainTank: 1200,
          adBlueTank: null,
          additionalTank: null,
          generatorFuelTank: null,
          totalCapacity: 1200,
        },
        telematics: {
          deviceId: '946573234',
          systemProvider: 'formula',
          mileage: null,
        },
        vehicleMeasurements: {
          length: 330,
          width: 330,
          height: 240,
          axleCount: 2,
          curbWeight: 3400,
        },
        cargoSpace: {
          loadCapacity: 280,
          volumeCapacity: 1000,
          chambersNumber: 2,
          tankCertificates: ['hacap'],
          dischargeTypes: ['pump'],
          europalletsNumber: null,
          length: null,
          width: null,
          height: null,
        },
        registrationCertificate: {
          technicalInspectionExpiryDate: '2025-12-19',
          registrationCertificateScanFileUuid: [
            'd84ecdd9-42b6-4cb5-8d4d-a3f8d7574b48',
          ],
        },
        co2Emissions: {
          emissionStandard: 'euro-1',
          emissionCertificate: '4a50ff47-9482-4ccf-b39a-8e890eab96ac',
        },
        thirdPartyLiabilityInsurance: {
          type: 'third-party-liability',
          contractFirstPageScan: '4544d9cb-1a80-4d3b-a853-a979de097627',
          insuranceNumber: '07/12',
          expiryDate: '2025-07-19',
          annualCost: {
            date: '2024-09-19',
            value: 1200,
            currency: 'eur',
          },
          insuranceSum: {
            date: '2024-09-19',
            value: 120000,
            currency: 'eur',
          },
        },
        comprehensiveInsurance: null,
        licensesAndPermits: {
          communityLicenseAbstractNumber: null,
          permitAbstractNumber: null,
        },
        additionalParameters: {
          adrClasses: null,
          isLowDeck: null,
          beltsNumber: null,
          hooksNumber: null,
          hasSanitaryEpidemiologicalStation: true,
          hasHaccp: true,
          hasHdsCrane: null,
          matsNumber: null,
          coilWellLength: null,
          hasCustomsSecurity: true,
          trailerSize: null,
          semiTrailerSize: null,
          hasLoadingsRamps: null,
          temperatureRange: null,
          hasTailLift: null,
          hasDumpContainer: null,
          hasForklift: null,
          otherParameters: null,
        },
        location: {
          latitude: null,
          longitude: null,
        },
        ownership: {
          type: 'own',
          vehicleValue: {
            date: '2024-09-19',
            value: 1200000,
            currency: 'eur',
          },
          depreciationPeriod: {
            from: '2024-04-19',
            to: '2025-04-19',
          },
          buyout: null,
          installment: null,
          selfDeposit: null,
          firstAndLastInstallmentDate: null,
          margin: null,
          interestRateType: null,
          isForSale: false,
        },
        allowedCountries: {
          group: 'europe',
          whitelist: [
            'ad',
            'al',
            'at',
            'az',
            'ba',
            'be',
            'bg',
            'by',
            'ch',
            'cy',
            'cz',
            'de',
            'dk',
            'ee',
            'es',
            'fi',
            'fr',
            'gb',
            'ge',
            'gr',
            'hr',
            'hu',
            'ie',
            'is',
            'it',
            'li',
            'lt',
            'lu',
            'lv',
            'md',
            'me',
            'mk',
            'mt',
            'nl',
            'no',
            'pl',
            'pt',
            'ro',
            'rs',
            'ru',
            'se',
            'si',
            'sk',
            'tr',
            'ua',
            'us',
          ],
        },
        tachograph: {
          model: 'model-4-0',
          make: 'vdo',
          nextLegalizationDate: '2025-07-19',
        },
        status: {
          value: 'active',
          color: null,
        },
        trailerUuid: null,
        drivers: {
          primaryDriver: null,
          secondaryDriver: null,
        },
        businessUuid: '25caa842-9765-480b-9436-0fc34600fbf7',
      },
      status: IntegrationStatus.ONLINE,
      lastActivityAt: '2024-09-19T07:15:41+02:00',
    },
    {
      uuid: '31e588fe-7b9b-4f1c-b3f4-956ab84da883',
      businessUuid: '123123123-123412',
      telematicsProvider: TelematicsProvider.ONYX,
      deviceId: '946573234',
      registrationNumber: null,
      origin: IntegrationOrigin.MANUAL,
      vehicle: {
        uuid: '4cc54ed2-a7fd-491f-8920-115726c4edf8',
        generalInformation: {
          category: 'straight-truck',
          type: 'chemical-tank',
          registrationNumber: 'GDWBLD0',
          vinNumber: '3HP6AKHY6ATLEH2A6',
          manufactureYear: 2010,
          firstRegistrationDate: '2014-09-19',
          make: 'Renault',
          model: 'Trafic',
          sideNumber: null,
          maxSpeed: 123,
          initialMileage: null,
          state: 'active',
          planningMode: 'auto',
        },
        fuelTankCapacity: {
          fuelType: 'diesel',
          mainTank: 1200,
          adBlueTank: null,
          additionalTank: null,
          generatorFuelTank: null,
          totalCapacity: 1200,
        },
        telematics: {
          deviceId: '946573234',
          systemProvider: 'formula',
          mileage: null,
        },
        vehicleMeasurements: {
          length: 330,
          width: 330,
          height: 240,
          axleCount: 2,
          curbWeight: 3400,
        },
        cargoSpace: {
          loadCapacity: 280,
          volumeCapacity: 1000,
          chambersNumber: 2,
          tankCertificates: ['hacap'],
          dischargeTypes: ['pump'],
          europalletsNumber: null,
          length: null,
          width: null,
          height: null,
        },
        registrationCertificate: {
          technicalInspectionExpiryDate: '2025-12-19',
          registrationCertificateScanFileUuid: [
            'd84ecdd9-42b6-4cb5-8d4d-a3f8d7574b48',
          ],
        },
        co2Emissions: {
          emissionStandard: 'euro-1',
          emissionCertificate: '4a50ff47-9482-4ccf-b39a-8e890eab96ac',
        },
        thirdPartyLiabilityInsurance: {
          type: 'third-party-liability',
          contractFirstPageScan: '4544d9cb-1a80-4d3b-a853-a979de097627',
          insuranceNumber: '07/12',
          expiryDate: '2025-07-19',
          annualCost: {
            date: '2024-09-19',
            value: 1200,
            currency: 'eur',
          },
          insuranceSum: {
            date: '2024-09-19',
            value: 120000,
            currency: 'eur',
          },
        },
        comprehensiveInsurance: null,
        licensesAndPermits: {
          communityLicenseAbstractNumber: null,
          permitAbstractNumber: null,
        },
        additionalParameters: {
          adrClasses: null,
          isLowDeck: null,
          beltsNumber: null,
          hooksNumber: null,
          hasSanitaryEpidemiologicalStation: true,
          hasHaccp: true,
          hasHdsCrane: null,
          matsNumber: null,
          coilWellLength: null,
          hasCustomsSecurity: true,
          trailerSize: null,
          semiTrailerSize: null,
          hasLoadingsRamps: null,
          temperatureRange: null,
          hasTailLift: null,
          hasDumpContainer: null,
          hasForklift: null,
          otherParameters: null,
        },
        location: {
          latitude: null,
          longitude: null,
        },
        ownership: {
          type: 'own',
          vehicleValue: {
            date: '2024-09-19',
            value: 1200000,
            currency: 'eur',
          },
          depreciationPeriod: {
            from: '2024-04-19',
            to: '2025-04-19',
          },
          buyout: null,
          installment: null,
          selfDeposit: null,
          firstAndLastInstallmentDate: null,
          margin: null,
          interestRateType: null,
          isForSale: false,
        },
        allowedCountries: {
          group: 'europe',
          whitelist: [
            'ad',
            'al',
            'at',
            'az',
            'ba',
            'be',
            'bg',
            'by',
            'ch',
            'cy',
            'cz',
            'de',
            'dk',
            'ee',
            'es',
            'fi',
            'fr',
            'gb',
            'ge',
            'gr',
            'hr',
            'hu',
            'ie',
            'is',
            'it',
            'li',
            'lt',
            'lu',
            'lv',
            'md',
            'me',
            'mk',
            'mt',
            'nl',
            'no',
            'pl',
            'pt',
            'ro',
            'rs',
            'ru',
            'se',
            'si',
            'sk',
            'tr',
            'ua',
            'us',
          ],
        },
        tachograph: {
          model: 'model-4-0',
          make: 'vdo',
          nextLegalizationDate: '2025-07-19',
        },
        status: {
          value: 'active',
          color: null,
        },
        trailerUuid: null,
        drivers: {
          primaryDriver: null,
          secondaryDriver: null,
        },
        businessUuid: '25caa842-9765-480b-9436-0fc34600fbf7',
      },
      status: IntegrationStatus.ONLINE,
      lastActivityAt: null,
    },
  ],
  limit: 10,
  page: 1,
  totalItems: 1,
};
