import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnyxPaginated, OnyxPagination } from '@onyx/angular';
import { map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { UnavailabilityForm } from '../../../../common/components/unavailabilities/unavailability-modal/unavailability-modal.component';
import { Unavailability } from '../../../../common/interfaces/common/unavailability';
import { BatchFileUpload } from '../../../../common/interfaces/utilities/batch-file-upload';
import { ApiService } from '../../../../common/services/api.service';
import { StorageService } from '../../../../common/services/storage.service';
import { DriverFormDto } from '../../driver-form/driver-form.component';
import { Driver } from '../interfaces/driver';
import { DriverCategory } from '../interfaces/driver-category';
import { DriverStatus } from '../interfaces/driver-status';
import { DRIVERS_MOCK } from '../mocks/drivers-mock';

@Injectable({
  providedIn: 'root',
})
export class DriversService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private storageService: StorageService,
  ) {
    super(http);
  }

  public listDrivers(
    params: {
      category: DriverCategory;
      status: DriverStatus[];
      freeOver24h: boolean;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Driver>> {
    // return this.get('/drivers');
    return of({
      ...DRIVERS_MOCK,
      page: params.page,
      limit: params.limit,
    });
  }

  public getDriver(_uuid: string): Observable<Driver> {
    // return this.get(`/drivers/${_uuid}`);
    return of(DRIVERS_MOCK.items[0]);
  }

  public addDriver(dto: DriverFormDto): Observable<void> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.post<void>('/drivers', dto)),
      tap(() => this._reload$.next()),
    );
  }

  public editDriver(uuid: string, dto: DriverFormDto): Observable<void> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.put<void>(`/drivers/${uuid}`, dto)),
      tap(() => this._reload$.next()),
    );
  }

  public archiveDriver(uuid: string): Observable<void> {
    return this.post<void>(`/drivers/${uuid}/archive`, undefined).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public getDriverUnavailabilities(
    driverUuid: string,
    params: {
      past: boolean;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Unavailability>> {
    return this.get(`/drivers/${driverUuid}/unavailabilities`, {
      params: {
        past: params.past,
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public addDriverUnavailability(
    driverUuid: string,
    form: UnavailabilityForm,
  ): Observable<void> {
    return this.uploadUnavailabilityFile(form).pipe(
      switchMap((form) =>
        this.post<void>(`/drivers/${driverUuid}/unavailabilities`, form),
      ),
      tap(() => this._reload$.next()),
    );
  }

  public editDriverUnavailability(
    driverUuid: string,
    uuid: string,
    form: UnavailabilityForm,
  ): Observable<void> {
    return this.uploadUnavailabilityFile(form).pipe(
      switchMap((form) =>
        this.put<void>(`/drivers/${driverUuid}/unavailabilities/${uuid}`, form),
      ),
      tap(() => this._reload$.next()),
    );
  }

  public deleteDriverUnavailability(
    driverUuid: string,
    uuid: string,
  ): Observable<void> {
    return this.delete<void>(
      `/drivers/${driverUuid}/unavailabilities/${uuid}`,
    ).pipe(tap(() => this._reload$.next()));
  }

  private uploadFiles(dto: DriverFormDto): Observable<DriverFormDto> {
    return of(dto).pipe(
      map((dto): BatchFileUpload[] => [
        {
          path: 'employmentConditions.scans',
          files: dto.employmentConditions.scans,
        },
        ...(dto.driverCard
          ? [
              {
                path: 'driverCard.scans',
                files: dto.driverCard.scans,
              },
            ]
          : []),
        {
          path: 'driversLicenseAndProfessionalQualifications.scans',
          files: dto.driversLicenseAndProfessionalQualifications.scans,
        },
        ...(dto.identityDocuments.passport
          ? [
              {
                path: 'identityDocuments.passport.scans',
                files: dto.identityDocuments.passport.scans,
              },
            ]
          : []),
        ...(dto.identityDocuments.identityDocument
          ? [
              {
                path: 'identityDocuments.identityDocument.scans',
                files: dto.identityDocuments.identityDocument.scans,
              },
            ]
          : []),
        ...(dto.identityDocuments.residenceCard
          ? [
              {
                path: 'identityDocuments.residenceCard.scans',
                files: dto.identityDocuments.residenceCard.scans,
              },
            ]
          : []),
        ...(dto.identityDocuments.polishCard
          ? [
              {
                path: 'identityDocuments.polishCard.scans',
                files: dto.identityDocuments.polishCard.scans,
              },
            ]
          : []),
        {
          path: 'clearCriminalRecordCertificate.scans',
          files: dto.clearCriminalRecordCertificate.scans,
        },
        ...dto.visas.map((visa, index) => ({
          path: `visas[${index}].scans`,
          files: visa.scans,
        })),
        ...dto.permissions.map((permission, index) => ({
          path: `permissions[${index}].scans`,
          files: permission.scans,
        })),
      ]),
      switchMap((data) => this.storageService.uploadBatch(data)),
      map((data) => this.storageService.mergeBatch(dto, data)),
    );
  }

  private uploadUnavailabilityFile(
    form: UnavailabilityForm,
  ): Observable<UnavailabilityForm> {
    return of(form).pipe(
      map((form): BatchFileUpload[] => [
        {
          path: 'file',
          files: form.file,
        },
      ]),
      switchMap((data) => this.storageService.uploadBatch(data)),
      map((data) => this.storageService.mergeBatch(form, data)),
    );
  }
}
