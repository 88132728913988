import { OnyxColor, OnyxOption } from '@onyx/angular';
import { TransportLicenseType } from '../../auth/common/enums/business/transport-license-type';
import { ContractType } from '../../dashboard/drivers/common/enums/contract-type';
import { DriverCategory } from '../../dashboard/drivers/common/interfaces/driver-category';
import { DriverStatus } from '../../dashboard/drivers/common/interfaces/driver-status';
import { FleetCategory } from '../../dashboard/fleet/common/enums/fleet-category';
import { FleetState } from '../../dashboard/fleet/common/enums/fleet-state';
import { OwnershipType } from '../../dashboard/fleet/common/enums/ownership-type';
import { VehiclePlanningMode } from '../../dashboard/fleet/common/enums/vehicle-planning-mode';
import { ContractorDocumentType } from '../../dashboard/management-panel/contractors/common/enums/contractor-document-type';
import { ContractorStatus } from '../../dashboard/management-panel/contractors/common/enums/contractor-status';
import { ContractorType } from '../../dashboard/management-panel/contractors/common/enums/contractor-type';
import { CompanyDictionaryCode } from '../../dashboard/management-panel/dictionaries/common/enums/company-dictionary-code';
import { EmployeeRole } from '../../dashboard/management-panel/employees/common/enums/employee-role';
import { EmployeeStatus } from '../../dashboard/management-panel/employees/common/enums/employee-status';
import { PointOfInterestType } from '../../dashboard/management-panel/points-of-interest/common/enums/point-of-interest-type';
import { AccountType } from './account-type';
import { GoodsSizeType } from './goods-size-type';

export enum DictionaryCode {
  ACCOUNT_TYPE = 'account-type',
  ADR_CLASS = 'adr-class',
  CHAMBERS_NUMBER = 'chambers-number',
  COMPANY_DICTIONARY_CODE = 'company-dictionary-code',
  CONTRACT_TYPE = 'contract-type',
  CONTRACTOR_BLOCK_REASON = 'contractor-block-reason',
  CONTRACTOR_DOCUMENT_TYPE = 'contractor-document-type',
  CONTRACTOR_STATUS = 'contractor-status',
  CONTRACTOR_TYPE = 'contractor-type',
  COUNTRIES_GROUP = 'countries-group',
  COUNTRY = 'country',
  CURRENCY = 'currency',
  DISCHARGE_TYPE = 'discharge-type',
  DRIVER_CATEGORY = 'driver-category',
  DRIVER_EXPERIENCE_LEVEL = 'driver-experience-level',
  DRIVER_PERMISSION_TYPE = 'driver-permission-type',
  DRIVER_SETTLEMENT_METHOD = 'driver-settlement-method',
  DRIVER_STATUS = 'driver-status',
  DRIVER_TECHNICAL_SKILL = 'driver-technical-skill',
  DRIVERS_LICENCE_CATEGORY = 'drivers-license-category',
  EMISSION_STANDARD = 'emission-standard',
  EMPLOYEE_ROLE = 'employee-role',
  EMPLOYEE_STATUS = 'employee-status',
  EMPLOYEE_UNAVAILABILITY_REASON = 'employee-unavailability-reason',
  FUEL_TYPE = 'fuel-type',
  GOODS_SIZE_TYPE = 'goods-size-type',
  INTEREST_RATE_TYPE = 'interest-rate-type',
  INTERFACE_LANGUAGE = 'interface-language',
  LANGUAGE = 'language',
  OWNERSHIP_TYPE = 'ownership-type',
  POINT_OF_INTEREST_TYPE = 'point-of-interest-type',
  SEMI_TRAILER_SIZE = 'semi-trailer-size',
  TACHOGRAPH_MAKE = 'tachograph-make',
  TACHOGRAPH_MODEL = 'tachograph-model',
  TANK_CERTIFICATE = 'tank-certificate',
  TRAILER_CATEGORY = 'trailer-category',
  TRAILER_MAKE = 'trailer-make',
  TRAILER_SIZE = 'trailer-size',
  TRANSPORT_LICENSE_TYPE = 'transport-license-type',
  VEHICLE_AXLE_COUNT = 'vehicle-axle-count',
  VEHICLE_CATEGORY = 'vehicle-category',
  VEHICLE_MAKE = 'vehicle-make',
  VEHICLE_PLANNING_MODE = 'vehicle-planning-mode',
  VEHICLE_STATE = 'vehicle-state',
  VEHICLE_TYPE = 'vehicle-type',
  VEHICLE_WEIGHT_CATEGORY = 'vehicle-weight-category',
}

export interface DictionaryValueByCode {
  [DictionaryCode.ACCOUNT_TYPE]: OnyxOption<AccountType>;
  [DictionaryCode.ADR_CLASS]: OnyxOption<string>;
  [DictionaryCode.CHAMBERS_NUMBER]: OnyxOption<number>;
  [DictionaryCode.COMPANY_DICTIONARY_CODE]: OnyxOption<CompanyDictionaryCode>;
  [DictionaryCode.CONTRACT_TYPE]: OnyxOption<ContractType>;
  [DictionaryCode.CONTRACTOR_BLOCK_REASON]: OnyxOption<string>;
  [DictionaryCode.CONTRACTOR_DOCUMENT_TYPE]: OnyxOption<ContractorDocumentType> & {
    color: OnyxColor;
  };
  [DictionaryCode.CONTRACTOR_STATUS]: OnyxOption<ContractorStatus>;
  [DictionaryCode.CONTRACTOR_TYPE]: OnyxOption<ContractorType>;
  [DictionaryCode.COUNTRIES_GROUP]: OnyxOption<never>;
  [DictionaryCode.COUNTRY]: OnyxOption<string> & { areaCode: string };
  [DictionaryCode.CURRENCY]: OnyxOption<string>;
  [DictionaryCode.DISCHARGE_TYPE]: OnyxOption<string>;
  [DictionaryCode.DRIVER_CATEGORY]: OnyxOption<DriverCategory>;
  [DictionaryCode.DRIVER_EXPERIENCE_LEVEL]: OnyxOption<string>;
  [DictionaryCode.DRIVER_PERMISSION_TYPE]: OnyxOption<string> & {
    requiredExpirationDate: boolean;
  };
  [DictionaryCode.DRIVER_SETTLEMENT_METHOD]: OnyxOption<string>;
  [DictionaryCode.DRIVER_STATUS]: OnyxOption<DriverStatus>;
  [DictionaryCode.DRIVER_TECHNICAL_SKILL]: OnyxOption<string>;
  [DictionaryCode.DRIVERS_LICENCE_CATEGORY]: OnyxOption<string> & {
    requireProfessionalQualifications: boolean;
    requireDriverCard: boolean;
  };
  [DictionaryCode.EMISSION_STANDARD]: OnyxOption<string>;
  [DictionaryCode.EMPLOYEE_ROLE]: OnyxOption<EmployeeRole> & {
    color: OnyxColor;
  };
  [DictionaryCode.EMPLOYEE_STATUS]: OnyxOption<EmployeeStatus> & {
    color: OnyxColor;
  };
  [DictionaryCode.EMPLOYEE_UNAVAILABILITY_REASON]: OnyxOption<string>;
  [DictionaryCode.FUEL_TYPE]: OnyxOption<string> & {
    units: [string, string];
    requireAdditionalTank: boolean;
  };
  [DictionaryCode.GOODS_SIZE_TYPE]: OnyxOption<GoodsSizeType>;
  [DictionaryCode.INTEREST_RATE_TYPE]: OnyxOption<string>;
  [DictionaryCode.INTERFACE_LANGUAGE]: OnyxOption<string>;
  [DictionaryCode.LANGUAGE]: OnyxOption<string>;
  [DictionaryCode.OWNERSHIP_TYPE]: OnyxOption<OwnershipType>;
  [DictionaryCode.POINT_OF_INTEREST_TYPE]: OnyxOption<PointOfInterestType>;
  [DictionaryCode.SEMI_TRAILER_SIZE]: OnyxOption<string>;
  [DictionaryCode.TACHOGRAPH_MAKE]: OnyxOption<string>;
  [DictionaryCode.TACHOGRAPH_MODEL]: OnyxOption<string>;
  [DictionaryCode.TANK_CERTIFICATE]: OnyxOption<string>;
  [DictionaryCode.TRAILER_CATEGORY]: OnyxOption<
    FleetCategory.SEMI_TRAILER | FleetCategory.TRAILER
  >;
  [DictionaryCode.TRAILER_MAKE]: OnyxOption<string>;
  [DictionaryCode.TRAILER_SIZE]: OnyxOption<string>;
  [DictionaryCode.TRANSPORT_LICENSE_TYPE]: OnyxOption<TransportLicenseType>;
  [DictionaryCode.VEHICLE_AXLE_COUNT]: OnyxOption<number>;
  [DictionaryCode.VEHICLE_CATEGORY]: OnyxOption<
    FleetCategory.SEMI_TRUCK | FleetCategory.STRAIGHT_TRUCK | FleetCategory.VAN
  >;
  [DictionaryCode.VEHICLE_MAKE]: OnyxOption<string>;
  [DictionaryCode.VEHICLE_PLANNING_MODE]: OnyxOption<VehiclePlanningMode>;
  [DictionaryCode.VEHICLE_STATE]: OnyxOption<FleetState>;
  [DictionaryCode.VEHICLE_TYPE]: OnyxOption<string>;
  [DictionaryCode.VEHICLE_WEIGHT_CATEGORY]: OnyxOption<string>;
}
