import { DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormHelper,
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxModalComponent,
  OnyxTextFieldComponent,
  OnyxToastService,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { ValidationHelper } from '../../../../../../common/helpers/validation.helper';
import { DictionariesService } from '../../../../../../common/services/dictionaries.service';
import { PreferencesService } from '../../../../../../common/services/preferences.service';
import { ContractorsRoute } from '../../../contractors.routes';
import { ContractorsRouterStateKey } from '../../enums/contractors-router-state-key';

@Component({
  selector: 'app-add-contractor-modal',
  standalone: true,
  imports: [
    OnyxModalComponent,
    TranslateModule,
    ReactiveFormsModule,
    OnyxDropdownComponent,
    OnyxTextFieldComponent,
    OnyxButtonComponent,
    AsyncPipe,
  ],
  templateUrl: './add-contractor-modal.component.html',
  styleUrl: './add-contractor-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddContractorModalComponent {
  protected readonly I18N = 'contractors.addContractorModal';

  protected readonly vatIdCountries$ =
    this.dictionariesService.getVatIdCountries();

  protected form = FormHelper.buildVatIdForm(this.fb, this.preferencesService);
  protected close$ = new Subject<void>();

  constructor(
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private preferencesService: PreferencesService,
    private dictionariesService: DictionariesService,
    private toastService: OnyxToastService,
    private router: Router,
  ) {}

  protected next(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const { country, vatId } = this.form.getRawValue();
    this.router.navigateByUrl(ContractorsRoute.ADD_CONTRACTOR, {
      state: {
        [ContractorsRouterStateKey.COUNTRY]: country,
        [ContractorsRouterStateKey.VAT_ID]: vatId,
      },
    });

    this.close$.next();
  }
}
