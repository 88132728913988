import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnyxPaginated, OnyxPagination } from '@onyx/angular';
import { Observable, Subject, tap } from 'rxjs';
import { ApiService } from '../../../../../common/services/api.service';
import { PointsOfInterestFormDto } from '../../points-of-interest-form/points-of-interest-form.component';
import { PointOfInterestType } from '../enums/point-of-interest-type';
import { PointOfInterest } from '../interfaces/point-of-interest';

// export const POINTS_OF_INTEREST: PointOfInterest[] = [
//   {
//     uuid: '67ccbc1c-19bd-49dd-8f78-5b8f7897aa9d',
//     name: 'Biedronka',
//     time: { averageServiceTime: '11:00:00' },
//     contractors: [
//       {
//         contractor: {
//           uuid: '6c854533-c949-44f1-960e-462204f563e0',
//           status: {
//             name: 'Aktywny',
//             value: 'active' as any,
//             color: 'green',
//           },
//           companyProfile: {
//             displayName: 'fdsgdfs',
//             companyName: 'ONYX ALFA PROSTA SPÓŁKA AKCYJNA',
//             country: 'pl',
//             vatId: '5811978512',
//             types: [
//               {
//                 name: 'Przewoźnik',
//                 value: 'carrier',
//                 color: 'violet',
//               },
//             ],
//             phone: {
//               areaCode: '+48',
//               number: '908234382',
//             },
//           },
//           contactPersons: [
//             {
//               firstName: 'string',
//               lastName: 'string',
//               email: 'jan.kowalski@onyxtms.com',
//               phone: {
//                 areaCode: '+48',
//                 number: '+48',
//               },
//               transId: 'string',
//               timocomId: 'string',
//               roles: ['business-admin'],
//               note: 'string',
//             },
//           ],
//           documents: [
//             {
//               type: 'agreement',
//               expirationDate: {
//                 date: '2023-12-13T10:30:00+01:00',
//                 indefinite: null,
//               },
//               scan: null,
//             },
//           ],
//           payments: {
//             paymentTermDays: 1,
//             tradeCreditLimit: {
//               date: '2024-02-20T00:00:00+01:00',
//               value: 55.99,
//               currency: 'pln',
//               euroValue: 12.96,
//               defaultCurrency: 'pln',
//               defaultCurrencyValue: 55.99,
//             },
//             hasUnlimitedCreditLimit: true,
//             electronicInvoiceEmail: null,
//           },
//           addresses: {
//             mainAddress: {
//               label: 'Grudziądzka 8A, 82-500 Kwidzyn, PL',
//               street: 'Grudziądzka',
//               houseNumber: '8A',
//               apartmentNumber: null,
//               zipCode: '82-500',
//               city: 'Kwidzyn',
//               countryCode: 'pl',
//               coordinates: {
//                 latitude: 53.73076,
//                 longitude: 18.92592,
//               },
//             },
//             correspondenceAddress: {
//               label: 'string string/string, string string, PL',
//               street: 'string',
//               houseNumber: 'string',
//               apartmentNumber: 'string',
//               zipCode: 'string',
//               city: 'string',
//               countryCode: 'pl',
//               coordinates: {
//                 latitude: 0,
//                 longitude: 0,
//               },
//             },
//             pointOfInterests: [
//               {
//                 contractorUuid: '6c854533-c949-44f1-960e-462204f563e0',
//                 pointOfInterestUuid: '67ccbc1c-19bd-49dd-8f78-5b8f7897aa9d',
//                 gate: null,
//               },
//             ],
//           },
//           block: {
//             reason: null,
//             reasonDescription: null,
//             end: {
//               date: null,
//               indefinite: null,
//             },
//           },
//         } as any,
//         contractorUuid: '60be7a4c-8f10-4b61-81f0-efd570a5e871',
//         pointOfInterestUuid: '67ccbc1c-19bd-49dd-8f78-5b8f7897aa9d',
//         gate: '15',
//       },
//     ],
//     phone: {
//       areaCode: '+48',
//       number: '908234382',
//     },
//     address: {
//       label: 'string',
//       street: 'Grudziądzka',
//       houseNumber: '8A',
//       apartmentNumber: '10',
//       zipCode: '82-500',
//       city: 'Kwidzyn',
//       countryCode: 'pl',
//       coordinates: {
//         latitude: 53.7291961,
//         longitude: 18.9339541,
//       },
//     },
//     businessHours: {
//       weekday: {
//         from: '10:00:00',
//         to: '15:00:00',
//       },
//       saturday: {
//         from: '10:00:00',
//         to: '15:00:00',
//       },
//       sunday: {
//         from: '10:00:00',
//         to: '15:00:00',
//       },
//     },
//     note: 'string',
//   },
// ];

@Injectable({
  providedIn: 'root',
})
export class PointsOfInterestService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(protected override http: HttpClient) {
    super(http);
  }

  public listPointsOfInterest(
    params: {
      type: PointOfInterestType | 'all';
    } & OnyxPagination,
  ): Observable<OnyxPaginated<PointOfInterest>> {
    return this.get('/point-of-interest', {
      params: {
        ...(params.type !== 'all' && { type: params.type }),
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public getPointOfInterest(uuid: string): Observable<PointOfInterest> {
    return this.get(`/point-of-interest/${uuid}`);
  }

  public addPointOfInterest(dto: PointsOfInterestFormDto): Observable<void> {
    return this.post<void>('/point-of-interest', dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public editPointOfInterest(
    uuid: string,
    dto: PointsOfInterestFormDto,
  ): Observable<void> {
    return this.put<void>(`/point-of-interest/${uuid}`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public deletePointOfInterest(uuid: string): Observable<void> {
    return this.delete<void>(`/point-of-interest/${uuid}`).pipe(
      tap(() => this._reload$.next()),
    );
  }
}
