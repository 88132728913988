import { HttpErrorResponse } from '@angular/common/http';
import { WritableSignal } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  FormHelper,
  OnyxOption,
  OnyxToastService,
  OnyxToastType,
} from '@onyx/angular';
import { captureException } from '@sentry/angular-ivy';
import { isArray } from 'lodash';
import { DictionaryCode } from '../enums/dictionary-code';
import { ValidationField } from '../interfaces/validation/validation-field';
import { DictionariesService } from '../services/dictionaries.service';

export class ValidationHelper {
  public static toggleControls(
    controls: AbstractControl | AbstractControl[],
    enabled: boolean,
  ): void {
    enabled ? this.enableControls(controls) : this.disableControls(controls);
  }

  public static enableControls(controls: AbstractControl | AbstractControl[]) {
    controls = isArray(controls) ? controls : [controls];
    for (const control of controls) {
      control.enable();
    }
  }

  public static disableControls(controls: AbstractControl | AbstractControl[]) {
    controls = isArray(controls) ? controls : [controls];
    for (const control of controls) {
      control.disable();
      FormHelper.reset(control);
    }
  }

  public static checkValidity(
    form: AbstractControl,
    toastService: OnyxToastService,
  ): boolean {
    if (form.valid) return true;

    FormHelper.submit(form);
    toastService.showCustom(OnyxToastType.INVALID_DATA);
    return false;
  }

  public static updateControl(
    control: AbstractControl,
    field: ValidationField,
  ): void {
    switch (field.type) {
      case 'conditional':
        break;

      case 'forbidden':
        control.disable();
        FormHelper.reset(control);
        break;

      case 'optional':
      case 'optional-in-list':
        control.enable();
        control.removeValidators(Validators.required);
        break;

      case 'required':
      case 'required-in-list':
        control.enable();
        control.addValidators(Validators.required);
        break;

      case 'required-in-range':
        control.enable();
        control.addValidators([
          Validators.required,
          Validators.min(field.min),
          Validators.max(field.max),
        ]);
        break;
    }

    control.updateValueAndValidity();
  }

  public static updateListControl(
    control: AbstractControl,
    field: ValidationField,
    translateService: TranslateService,
    dictionariesService: DictionariesService,
    dictionaryCode: DictionaryCode,
    options: WritableSignal<OnyxOption<unknown>[] | null>,
    multiple: boolean,
  ): void {
    this.updateControl(control, field);
    options.set(null);

    if (field.type === 'forbidden') return;

    if (
      field.type === 'required-in-list' ||
      field.type === 'optional-in-list'
    ) {
      options.set(
        field.values.map((value) => {
          const key = `${dictionaryCode}.${value}`;

          let name = translateService.instant(key);
          if (name === key) name = value;

          return { name, value };
        }),
      );

      if (field.values.length === 1) {
        control.setValue(multiple ? field.values : field.values[0]);
        control.disable();
      } else {
        const controlValue = control.value;
        if (multiple) {
          control.setValue(
            controlValue.filter((value: any) => field.values.includes(value)),
          );
        } else if (!field.values.includes(controlValue)) {
          control.setValue(null);
        }
      }
    } else {
      dictionariesService
        .getDictionary(dictionaryCode)
        .subscribe((dictionary) => options.set(dictionary));
    }
  }

  public static handleUnexpectedError(
    error: HttpErrorResponse,
    toastService: OnyxToastService,
  ) {
    toastService.showCustom(OnyxToastType.SERVER_ERROR);
    captureException(error);
  }
}
